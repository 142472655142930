












import { defineComponent, reactive, watch } from '@vue/composition-api'
import { AxiosInstance } from 'axios'

export default defineComponent({
  setup(_, { root }) {

    const state = reactive({
      loading: false,
      loaded: false,
      items: [],
      search: ''
    })

    const model = reactive({
      participant: null
    })

    watch(() => state.search, () => {
      const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

      state.loading = true

      axiosInstance
        .get('participant/global')
        .then(({ data: { participants } }) => {
          const result = participants.map((participant: any, index: number) => ({ id: index, name: '', ...participant }))
          state.items = result.reduce((a: any, o: any) => (a.push(o.name), a), [])
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = []
          } else {
            console.log(error)
          }
        })
        .finally(() => {
          state.loaded = true
          state.loading = false
        })
    })

    return { state, model }
  }
})
